import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Bogucianka = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Bogucianka",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#2ABA9A",
        navClass: "bogucianka",
        ogImage: require("../../../assets/img/portfolio/bogucianka_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/bogucianka/",
      }}
    >
      <PortfolioHeader name="bogucianka" height="176" />
      <section className="container-fluid bogucianka_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Bogucinka Housing Estates</h1>
              <ul>
                <li>Logo</li>
                <li>Website</li>
                <li>Lead Generation Campaign</li>
                <li>Banner Ads</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Our Client belongs to Genus Group Real Estate Developer, which
                has the following facilities in its portfolio: Zacisze 4,
                Kurniki 9, Przemiarki 14, Józefińska 31, Siemiradzkiego 25 and
                Dom nad Młynówka in Kraków, as well as a group of semi-detached
                houses in Januszowice, Palace in Cianowice T. Talowskiego, and
                the current implementation of the Group, i.e. Bogucianka housing
                estate.
              </p>
              <p>
                Our cooperation includes support for sales activities of the
                Bogucianka housing estate. The scope of cooperation includes the
                development of a functional layout of the website, along with
                the implementation of the project, as well as the planning and
                running of a lead generation marketing campaign, which we
                supported by actively running the investment’s fanpage.
              </p>
              <p>Cel:</p>
              <ol>
                <li>Create tools for presenting the offer (website, logo)</li>
                <li>
                  Provide sales support - lead generation campaign, advertising
                  banners
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bogucianka_section_25">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/bogucianka_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/bogucianka_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>74</span>
                  <span>50</span>
                  <span>60</span>
                  <span>33</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>71</span>
                  <span>65</span>
                  <span>64</span>
                  <span>68</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>63</span>
                  <span>86</span>
                  <span>81</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>40</span>
                  <span>40</span>
                  <span>40</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bogucianka_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <div className="inner">
              <h3>Internet service</h3>
              <p>
                The website www.bogucianka.pl was created for a developer
                operating on the real estate development market. The most
                important task we faced when designing the site was to create
                unique graphic design that will allow to show a high-standard
                investment located in Tyniec, a region of Kraków, Poland, called
                it’s green lungs, that is extremely attractive for anyone who
                values ​​peace and great space for physical activities.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/bogucianka_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters align-items-center row_2">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/bogucianka_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-7">
            <div className="inner">
              <p>
                We also wanted to focus on presenting the unique added value for
                this investment, which undoubtedly is the 30 ares of
                recreational and leisure area within the housing estate, which
                is accessible only for the residents.
              </p>
              <p>
                The graphic layout was also determined with the thought of
                maximizing the usability of the website and the Client's
                business goals. The most important function of the site was to
                generate leads.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-2">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/bogucianka_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-end row_4">
          <div className="col-md-2">
            <img
              className="img-fluid mobile"
              src={require("../../../assets/img/portfolio/bogucianka_main_6.png")}
              alt=""
            />
          </div>
          <div className="col-md-9 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/bogucianka_main_5.png")}
              alt=""
            />
            <p>
              In addition to the site’s design, we also coded it to a fully
              functioning website, to which we have also installed the necessary
              analytical tools - including, among others, Google Analytics and
              Google Tag Manager. This made it possible to track the sources of
              inquiries while increasing the effectiveness of When's marketing
              campaign.
            </p>
          </div>
        </div>
        <div className="row align-items-center row_5">
          <div className="col-md-6">
            <div className="inner">
              <h3>Lead Generation Campaign</h3>
              <p>
                We treated the first month of the campaign as a test one - we
                analyzed data illustrating user behavior, the ratio of achieving
                our goals and the level of conversion. We have ran an extensive
                campaign on the Google search and advertising network in the
                Lesser Poland region to reach people who may be interested in
                buying a house near Krakow. We selected appropriate keywords
                related to the real estate market and web portals connected with
                it, on which we displayed our advertising banners. Our campaigns
                also followed each user sending the contact form via website. At
                the same time, we ran an investment fanpage and extensive
                Facebook marketing campaigns.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/bogucianka_main_7.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Bogucianka;
